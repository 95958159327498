import React, { useEffect, useState } from "react";
import { ApiResponse, School } from "./Dashboard";
import TotalsTable, { Total } from "./TotalsTable.tsx";
import api from '../../api';
import { CircularProgress } from "@mui/material";
import useParentNotifications from "../../hooks/useParentNotifications.ts";

interface SchoolProps {
  school: School;
}

interface SchoolResponse {
  grade: string;
  count: number;
}

const GRADE_NAMES = {
  JK: "Pre-Primary/Jr Kindergarten",
  K: "Primary/Kindergarten",
  1: "Grade 1",
  2: "Grade 2",
  3: "Grade 3",
  4: "Grade 4",
  5: "Grade 5",
  6: "Grade 6",
  7: "Grade 7",
  8: "Grade 8",
  9: "Grade 9",
  10: "Grade 10",
  11: "Grade 11",
  12: "Grade 12"
};

const formatSchoolData = (responseData): Array<Total> => {
  return responseData.map((schoolResponse: SchoolResponse): Total => {
    return {
      label: GRADE_NAMES[schoolResponse.grade],
      count: schoolResponse.count,
      value: schoolResponse,
      key: schoolResponse.grade
    };
  });//.sort((a: Total, b: Total) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
};

const IndividualSchool: React.FC<SchoolProps> = ({ school }) => {
  const [schoolData, setSchoolData] = useState<Array<Total>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [childCount, setChildCount] = useState<number>(0);

  useParentNotifications("dashboard");

  useEffect(() => {
    const loadData = async () => {
      const response = await api.get("/pledges", { params: { school: school.id } });
      const responseData = response.data as ApiResponse;
      const data = formatSchoolData(responseData.data);
      setChildCount(responseData.child_count);
      setSchoolData(data);
      setLoading(false);
    };

    loadData();
  }, []);

  if (loading) {
    return (
      <div className="p-16">
        <CircularProgress variant="indeterminate" />
      </div>
    );
  }

  return (
    <div className="w-full p-10 flex flex-col gap-8 items-center">
      <div className="flex flex-row gap-x-5 w-full p-y-10">
        <div className="rounded-xl flex-1 bg-white p-6 flex flex-col items-center gap-8">
          <h3 className="text-red-500 font-bold text-7xl">{childCount}</h3>
          <p className="uppercase font-bold text-3xl">SIGNED PLEDGES</p>
        </div>
      </div>

      <div className="w-full h-[1px] bg-neutral-300" />

      <TotalsTable
        labelHeader="Grade"
        data={schoolData} />
    </div>
  )
};

export default IndividualSchool;
