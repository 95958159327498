import { useEffect } from 'react'

const useParentNotifications = (component: string) => {
    useEffect(() => {
        let message = {
            height: document.body.scrollHeight,
            width: document.body.scrollWidth,
            component,
        }

        window.top?.postMessage(message, '*')
    })
}

export default useParentNotifications
